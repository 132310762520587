import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { roleTypes } from '@shared/helpers/types.helper';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { AssetCategorysService } from '@shared/services/assets-categories.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { UsersService } from '@shared/services/users.service';
import jwtDecode from 'jwt-decode';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-users',
  templateUrl: './modals.users.component.html'
})
export class ModalsUsersComponent
  extends ModalDirective<IUser>
  implements OnInit {
  @Input() public openModal: Subject<IUser>;

  public title = String('MODALS.USERS.ADD.TITLE');
  public description = String('MODALS.USERS.ADD.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public disableButtons = Boolean(false);
  public entry: FormGroup;
  public roles: { value: number; label: string }[] = roleTypes;
  public role: number;
  public categories: Partial<IAssetCategory>[] = [];
  public isAdmin = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _assetsCategory: AssetCategorysService,
    private readonly _logger: LoggerService,
    private readonly _users: UsersService,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.getCategories();

    this.openModal.subscribe((u: IUser) => {
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
      this.role = Number(payload.role);
      const employeeRole = Number(payload.employeeRole);
      this.isAdmin = Boolean(employeeRole === 0 && this.role === 1);

      const password = this.entry.controls['password'];

      if (u) {
        this.title = 'MODALS.USERS.EDIT.TITLE';
        this.description = 'MODALS.USERS.EDIT.DESCRIPTION';
        password.setValidators([]);

        this.entry.patchValue({
          ...u,
          categories: u?.categories?.map((item: any) => item?.id),
        });
      } else {
        this.title = 'MODALS.USERS.ADD.TITLE';
        this.description = 'MODALS.USERS.ADD.DESCRIPTION';

        password.setValidators([Validators.required]);
        this.entry.patchValue({
          role: 1,
          isActive: true
        });
      }

      password.updateValueAndValidity();
    });
  }

  public getCategories() {
    const url = `GET /assets-categories`;
    this._assetsCategory.get(1, []).subscribe(
      (res: IAssetCategory[]) => {
        this.categories = res;
        this.createForm();
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /users`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /users/${value.id}`;
      }

      value.categories = value.categories?.length > 0 ? value?.categories?.map((id: string) => ({ id })) : [];

      this._users[method](value).subscribe(
        (res: IUser) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal(res);

          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, `${url} failed`, err);

          const errors = err.errors;

          if (err.errors[0]?.code === 500) {
            if(err.errors.length > 0 && err.errors[0]?.code === 500) {
              this.errors = [
                {
                  title: 'An error has occured.',
                  detail: 'This email may already be in use. Please try another one.'
                }
              ];
            } else {
              this.errors = err.errors;
            }
          } else if (errors) {
            this.errors = errors;
            this._toasts.error(errors[0].detail);
          }
          

          this.entry.enable();
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      role: ['', [Validators.required]],
      password: ['', [Validators.required]],
      isActive: [true, Validators.required],
      categories: ['', []],
      isNewPasswordChecked: [false, []],
    });
  }
}
